import React, { useMemo } from "react"

import { useLocation } from "@gatsbyjs/reach-router"
import Head from "decentraland-gatsby/dist/components/Head/Head"
import NotFound from "decentraland-gatsby/dist/components/Layout/NotFound"
import useAsyncMemo from "decentraland-gatsby/dist/hooks/useAsyncMemo"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Container } from "decentraland-ui/dist/components/Container/Container"

import BlogNavigation from "../../../components/Layout/BlogNavigation"
import Layout from "../../../components/Layout/Layout"
import Post from "../../../components/Layout/Post"
import { getBlogpostPreview } from "../../../modules/contentful"

export default function PostPage(props: any) {
  const location = useLocation()
  const options = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return {
      id: params.get("contentful_id") ?? undefined,
      env: params.get("contentful_env") ?? undefined,
      token: params.get("token") ?? undefined,
    }
  }, [location.search])

  const [post, postState] = useAsyncMemo(
    async () => getBlogpostPreview(options),
    [options]
  )

  const loading = !postState.loaded || postState.loading

  // useEffect(() => {
  //   if (
  //     !loading &&
  //     post &&
  //     (!post.fields.author ||
  //       !post.fields.banner ||
  //       !post.fields.body ||
  //       !post.fields.category ||
  //       !post.fields.description ||
  //       !post.fields.id ||
  //       !post.fields.publishedDate ||
  //       !post.fields.title)
  //   ) {
  //     navigate(locations.blogs())
  //   }
  // }, [post, loading])

  if (postState.loaded && !postState.loading && !post) {
    return (
      <Layout
        {...props}
        isFullscreen
        isOverlay
        hideFooter={false}
        className={"layout__blog"}
      >
        <Container style={{ paddingTop: "75px" }}>
          <NotFound />
        </Container>
      </Layout>
    )
  }

  return (
    <Layout
      {...props}
      isFullscreen
      isOverlay
      hideFooter={false}
      className={TokenList.join(["layout__blog", loading && "loading"])}
    >
      <Head
        title={post?.fields.title || ""}
        description={post?.fields.description || ""}
      />
      <BlogNavigation active={props.category} />
      <Post post={post} loading={loading} />
    </Layout>
  )
}
